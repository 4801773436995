
export const generateCodeVerifier = () => {
    const array = new Uint32Array(32);
    window.crypto.getRandomValues(array);
    return Array.from(array, (dec) => ('0' + dec.toString(16)).substr(-2)).join('');
  };
  
  const base64UrlEncode = (str: string) => {
    return btoa(str)
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  };
  
 export const generateCodeChallenge = async (codeVerifier: string) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await window.crypto.subtle.digest('SHA-256', data);
    const base64Digest = base64UrlEncode(String.fromCharCode(...Array.from(new Uint8Array(digest))));
    return base64Digest;
  };
  
 export const generateState = () => {
    const array = new Uint32Array(16);
    window.crypto.getRandomValues(array);
    return Array.from(array, (dec) => ('0' + dec.toString(16)).substr(-2)).join('');
  };
  
    // Decode UTF-8 encoded as base64 to a js native UTF-16 string
    // Given an ASCII string of UTF-8 encoded as base64, atob() will convert each input character to a byte value,
    // but in UTF-8 a character may be encoded in more than a single byte.
    // We can correctly convert from UTF-8 to UTF-16 by converting each output byte to a URI encoded value padded with '0'
    // and using decodeURIComponent to convert from UTF-8 to UTF-16. There may be more efficient ways to do this.
    // see https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
    export const b64DecodeUnicode = (encoded: string) => {
      // make the encoded string url safe for decodeURIComponent
      const base64 = encoded.replace(/-/g, '+').replace(/_/g, '/');
  
      return decodeURIComponent(Array.prototype.map.call(atob(base64), function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
    };